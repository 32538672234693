<!-- 营销管理 实体充电卡 离线卡管理详情 -->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus :labelWidth="90">
        <div slot="header" class="clearfix">
          <span class="title">绑卡详情</span>
        </div>
        <ykc-detail-item-plus label="电桩编码">
          <span>{{ pileCode }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="电站名称">
          <span>{{ stationName }}</span>
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-table
        style="margin-top: 16px"
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        :operateButtons="tableOperateButtons"></ykc-table>
    </div>
  </scroll-layout>
</template>
<script>
  import { chargingCardManage } from '@/service/apis';

  export default {
    data() {
      return {
        pileCode: '',
        stationName: '',
        detailId: '',
        tableData: [],

        tableColumns: [
          { label: '卡面卡号', prop: 'cardNo' },
          { label: '物理卡号', prop: 'physicalCardNo' },
        ],
        tableTitle: '已下发卡号信息',

        tableOperateButtons: [],
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    created() {
      this.detailId = this.$route.query.id;
      this.getDetail();
    },
    methods: {
      getDetail() {
        chargingCardManage
          .offDetail({ id: this.detailId })
          .then(res => {
            this.pileCode = res.pileCode;
            this.stationName = res.stationName;
            this.tableData = res.cardNos.map(item => {
              return {
                cardNo: item.cardNo,
                physicalCardNo: item.physicalCardNo,
              };
            });
          })
          .catch(() => {
            this.tableData = [];
          });
      },
    },
  };
</script>
